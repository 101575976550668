import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

const Success = props => (
  <Layout>
    <Helmet>
      <title>Success Page</title>
      <meta name="description" content="Success Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Thank you for reaching out</h1>
          </header>
          <p>We'll get back to you as soon as we can <span role="img" aria-label="love">❤️</span></p>
        </div>
      </section>
    </div>
  </Layout>
);

export default Success;